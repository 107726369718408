<template>
  <div>
    <b-card>
      <div class="text-right mb-3">
        <b-select
          v-model="perPage"
          :options="pageOptions"
          style="width: fit-content"
        />
      </div>
      <b-table
        hover
        responsive
        show-empty
        empty-text="Aucune nature enrégistrée"
        :items="items"
        :fields="fields"
        :busy="loaded"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="primary"
              label="Loading..."
            />
          </div>
          <div class="d-flex justify-content-center">
            <strong>Chargement en cours...</strong>
          </div>
        </template>
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(action)="data">
          <span class="whitespace-no-wrap">
            <feather-icon
              icon="EditIcon"
              svg-classes="w-5 h-5 hover:text-warning stroke-current"
              class="text-warning"
              @click="openEditModal(data.item)"
            />

            <feather-icon
              icon="TrashIcon"
              svg-classes="w-5 h-5 hover:text-danger stroke-current"
              class="ml-2 text-danger"
              @click="openDeleteModal(data.item)"
            />
          </span>
        </template>
      </b-table>

      <div class="mt-2">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="center"
        />
      </div>
    </b-card>

    <b-modal
      v-model="showEditModal"
      centered
      hide-footer
      title="Modification"
      :no-close-on-backdrop="updateProcess"
    >
      <b-form-group
        label="Nom"
      >
        <b-form-input
          v-model="$v.currentShipConsignee.name.$model"
          :formatter="formatter"
          :class="{ 'is-invalid': $v.currentShipConsignee.name.$error }"
        />
        <div
          v-if="$v.currentShipConsignee.name.$error"
          class="invalid-feedback"
        >
          <span
            v-if="!$v.currentShipConsignee.name.required"
          >Le nom est requis.</span>
        </div>
      </b-form-group>
      <b-form-group
        label="Description"
      >
        <b-form-input
          v-model="$v.currentShipConsignee.description.$model"
          :formatter="formatter"
          :class="{ 'is-invalid': $v.currentShipConsignee.description.$error }"
        />
        <div
          v-if="$v.currentShipConsignee.description.$error"
          class="invalid-feedback"
        >
          <span
            v-if="!$v.currentShipConsignee.description.required"
          >La description est requise.</span>
        </div>
      </b-form-group>
      <b-form-group
        label="Adresse"
      >
        <b-form-input
          v-model="$v.currentShipConsignee.address.$model"
          :formatter="formatter"
          :class="{ 'is-invalid': $v.currentShipConsignee.address.$error }"
        />
        <div
          v-if="$v.currentShipConsignee.address.$error"
          class="invalid-feedback"
        >
          <span
            v-if="!$v.currentShipConsignee.address.required"
          >L'adresse est requise.</span>
        </div>
      </b-form-group>
      <hr>

      <div>
        <div
          v-if="updateProcess"
          class="mt-3 text-center"
        >
          <b-spinner
            label="Loading"
            variant="primary"
          />
        </div>

        <div class="float-left">
          <b-button
            variant="light"
            :disabled="updateProcess"
            @click="closeEditModal"
          >Quitter</b-button>
        </div>

        <div class="float-right">
          <b-button
            variant="outline-success"
            :disabled="updateProcess"
            @click="tryToUpdate"
          >Mofidier</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="showDeleteModal"
      centered
      hide-footer
      title="Suppression"
      :no-close-on-backdrop="deleteProcess"
    >
      <div class="text-justify">
        Voulez-vous vraiment supprimer la Consignataire de dénomination {{ currentShipConsignee.name }} ayant pour description " {{ currentShipConsignee.description }}" et adresse {{ currentShipConsignee.address }} ?
      </div>
      <hr>

      <div>
        <div
          v-if="deleteProcess"
          class="mt-3 text-center"
        >
          <b-spinner
            label="Loading"
            variant="primary"
          />
        </div>

        <div class="float-left">
          <b-button
            variant="light"
            :disabled="deleteProcess"
            @click="closeDeleteModal"
          >Quitter</b-button>
        </div>

        <div class="float-right">
          <b-button
            variant="outline-success"
            :disabled="deleteProcess"
            @click="tryToDelete"
          >Supprimer</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      items: [],
      loaded: false,
      totalItems: null,
      showEditModal: false,
      showDeleteModal: false,
      currentShipConsignee: {
        id: null,
        name: null,
        address: null,
        description: null,
      },
      fields: [
        { key: 'index', label: 'N°' },
        { key: 'name', label: 'Nom' },
        { key: 'description', label: 'Description' },
        { key: 'address', label: 'Adresse' },
        { key: 'action', label: 'Action' },
      ],
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 15, 20],
    }
  },
  validations: {
    currentShipConsignee: {
      name: {
        required,
      },
      id: {
        required,
      },
      address: {
        required,
      },
      description: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      token: 'token',

      updateProcess: 'updateShipConsigneeProcess',
      updateSuccess: 'updateShipConsigneeSuccess',
      updateError: 'updateShipConsigneeError',

      deleteProcess: 'deleteShipConsigneeProcess',
      deleteSuccess: 'deleteShipConsigneeSuccess',
      deleteError: 'deleteShipConsigneeError',
    }),
  },
  watch: {
    updateSuccess(val) {
      if (val) {
        switch (val.valid) {
          case 1:
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification',
                text: val.message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
            break
          default:
            this.closeEditModal()
            this.handleAllConsignees()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification',
                text: 'Modifiée avec succès.',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            break
        }
      }
    },
    updateError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Modification',
            text: 'Une erreur est survenue lors de la modification.',
            icon: 'CheckCircleIcon',
            variant: 'danger',
          },
        })
      }
    },

    deleteSuccess(val) {
      if (val) {
        this.closeDeleteModal()
        this.handleAllConsignees()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Suppression',
            text: val.res,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    deleteError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Suppression',
            text: 'Une erreur est survenue lors de la suppression.',
            icon: 'CheckCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    currentPage() {
      this.handleAllConsignees()
    },
    perPage() {
      this.handleAllConsignees()
    },
  },
  mounted() {
    this.handleAllConsignees()
  },
  methods: {
    ...mapActions(['updateShipConsignee', 'deleteShipConsignee']),
    formatter(value) {
      return value.toUpperCase()
    },
    async handleAllConsignees() {
      this.loaded = true
      this.items = await this.$http.get(`/api/ship-consignee/find-all-paged/${this.currentPage}/${this.perPage}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(res => {
        this.loaded = false
        this.totalItems = res.data.total_count
        return res.data.items
      })
        .catch(() => {
          this.loaded = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Consignataire',
              text: 'Une erreur est survenue lors de la récupération.',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
    openEditModal(item) {
      this.currentShipConsignee = {
        name: item.name,
        id: item.id.uid,
        description: item.description,
        address: item.address,
      }
      this.showEditModal = true
    },
    closeEditModal() {
      this.showEditModal = false
      this.currentShipConsignee = {
        id: null,
        name: null,
        description: null,
        address: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    openDeleteModal(item) {
      this.currentShipConsignee = {
        name: item.name,
        description: item.description,
        id: item.id.uid,
        address: item.address,
      }
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.showDeleteModal = false
      this.currentShipConsignee = {
        id: null,
        name: null,
        address: null,
        description: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    tryToUpdate() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      this.updateShipConsignee({
        id: this.currentShipConsignee.id,
        name: this.currentShipConsignee.name,
        description: this.currentShipConsignee.description,
        address: this.currentShipConsignee.address,
      })
    },
    tryToDelete() {
      this.deleteShipConsignee({
        id: this.currentShipConsignee.id,
      })
    },
  },
}
</script>
